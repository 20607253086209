.nav-tabs .nav-link {
    border: none !important;
}

.nav-link {
    color: #9EA4B5 !important;
}

.app_list {
    overflow-y: auto !important;
    scrollbar-color: #9EA4B5 #e4e4e4;
    transition: scrollbar-color 0.3s ease-out;
    scrollbar-width: thin;
}

.app_list::-webkit-scrollbar {
    width: 15px;
}

.app_list::-webkit-scrollbar-track {
    border-radius: 100px;
}

.app_list::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #9EA4B5;
}