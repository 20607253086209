.msg,
.user_info {
    display: flex;
    flex-direction: column;
}


.filter_sidebar .MuiListItemButton-gutters {
    border-radius: 10px;
    font-weight: 500 !important;
    margin: 0px 10px;
    padding: 4px 18px;
}

.filter_sidebar .Mui-selected {
    color: #00a82d !important;
    background: #efffed !important;
    border-radius: 10px;
    font-weight: 500 !important;
    margin: 0px 10px;
    padding: 4px 18px;
}

.chat_hover_view:hover {
    background: #F0F2F8;
    /* border-left: 3px solid #00a82d; */
}

.chat_hover_view:hover .userImage {
    border: 1px solid #00a82d !important;
}

.chat_hover_view:hover .chat_list_name {
    color: #00a82d !important;
}


.list_scroll {
    overflow-y: auto !important;
    scrollbar-color: #B8BDCC #e4e4e4;
    transition: scrollbar-color 0.3s ease-out;
    scrollbar-width: thin;
}

.list_scroll::-webkit-scrollbar {
    width: 15px;
}

.list_scroll::-webkit-scrollbar-track {
    border-radius: 100px;
}

.list_scroll::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #B8BDCC;
}












.msg_body {
    display: flex !important;
    height: 60%;
    flex-direction: column !important;
    overflow-y: auto !important;
    scrollbar-color: #9EA4B5 #e4e4e4 !important;
    transition: scrollbar-color 0.3s ease-out !important;
    scrollbar-width: thin !important;
}

.msg_body::-webkit-scrollbar {
    width: 15px !important;
}

.msg_body::-webkit-scrollbar-track {
    border-radius: 100px !important;
}

.msg_body::-webkit-scrollbar-thumb {
    border-radius: 100px !important;
    border: 5px solid transparent !important;
    background-clip: content-box !important;
    background-color: #9EA4B5 !important;
}

.msg_empty .empty_chat_img {
    width: 200px !important;
    height: 200px !important;
}



.chatViewU .chat_bubble {
    font-weight: 400;
    font-size: 14px;
    max-width: 300px;
    min-width: 150px;
    overflow-wrap: break-word;
    padding: 6px 10px;
    color: #3f4a5f;
    background: #efffed;
    border-radius: 6px 6px 6px 0px;
}

.chatViewU .chat_bubble_for_template {
    max-width: 300px;
    min-width: 150px;
}

.chatViewB .chat_bubble {
    font-weight: 400;
    font-size: 14px;
    max-width: 300px;
    min-width: 150px;
    overflow-wrap: break-word;
    padding: 6px 10px;
    color: #3f4a5f;
    background: #f0f2f8;
    border-radius: 6px 6px 0px 6px;
}

.chatViewB .chat_bubble_private {
    font-weight: 400;
    font-size: 14px;
    max-width: 300px;
    min-width: 150px;
    overflow-wrap: break-word;
    padding: 6px 10px;
    color: #3f4a5f;
    background: #FFFCEC;
    border-radius: 6px 6px 0px 6px;
}

.chatViewB,
.chatViewU {
    position: relative;
    margin-bottom: 6px;
}

.chat_Business {
    margin-left: auto !important;
    right: 0px !important;
}

.chat_image_view {
    width: 280px !important;
    height: 180px !important;
}

.chat_image_view img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.chat_video_view {
    width: 280px !important;
    height: 180px !important;
}

.chat_video_view video {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.dragDropView {
    align-items: center;
    display: flex;
}

.dragDropView .dragDropLabel img {
    height: 300px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.dragDropView .dragDropLabel video {
    height: 280px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.dragDropView .dragDropLabel {
    order: 2;
    margin: auto auto;
}

.interactive_btns {
    border: 2px solid #f0f2f8 !important;
    background: #fff !important;
    color: #0B86DF !important;
}

.interactive_btns:hover {
    border: 2px solid #f0f2f8 !important;
    background: #fff !important;
    color: #0B86DF !important;
}

















.msg_footer .nav-tabs,
.choose_to_send_dialog .nav-tabs {
    border-bottom: none !important;
}

.msg_footer .nav-tabs .nav-link,
.choose_to_send_dialog .nav-tabs .nav-link {
    border: none !important
}

.msg_footer .nav-link,
.choose_to_send_dialog .nav-link {
    color: #9EA4B5 !important;
}

.msg_footer .nav-tabs .nav-item.show .nav-link,
.choose_to_send_dialog .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-bottom: 3px solid #00a82d !important;
    color: #00a82d !important;
}