.campaign_ui .css-ahj2mt-MuiTypography-root {
  font-size: 12px !important;
}

.campaign_ui .table>:not(caption)>*>* {
  padding: 6px 14px !important;
}


.carousel-indicators [data-bs-target] {
  background-color: #00a82d !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  position: relative;
  margin-top: -39% !important;
  left: -37% !important;
}

.startCampaign {
  position: absolute;
  left: -37% !important;
}

/**Create Campaign starts here*/
/**HEADER Starts*/
.create-campaign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  font-family: "Roboto", sans-serif;
}

.info {
  padding-top: 2px;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 10px;
  top: 10px;
}

.broadcast-create {
  font-size: 18px;
  color: #212529;
  line-height: 12px;
  font-weight: bold;
}

.broadcast-powered {
  font-size: 10px;
  color: darkgray;
}

.btn-camp {
  height: 45px;
  width: 161px;
  border-radius: 8px;
  margin-right: 6px;
}

.btn-preview-disabled {
  background-color: #e4e4e477;
  cursor: not-allowed;
  color: #b8bdcc;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-save-disabled {
  background-color: #e4e4e477;
  color: #b8bdcc;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-save-active {
  background-color: #00a82d;
  color: #fff;
}

.btn-save-active-cancel {
  background-color: #9EA4B5;
  color: #fff;
}

.btn-preview-active {
  background-color: #e4e4e477;
  color: #616874;
}

/**HEADER Ends*/

.channel-list-dialog-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 420px;
  padding: 1rem;
  background-color: #d4e4d35d;
}

.channel-search {
  width: 380px;
  padding: 10px;
  margin-bottom: 10px;
}

.channel-list {
  color: #616874;
  width: 380px;
  padding: 2px;
  padding-left: 8px;
  margin-bottom: 0px;
  font-family: "Roboto" sans-serif;
  font-weight: 500;
}

.channel-list:hover {
  background-color: #cadfc9e0;
  cursor: pointer;
}

.channel-wa {
  /* font-weight: normal; */
  color: #9ea4b5;
}

.select-box-completed,
.channel-select {
  color: #616874;
  background-color: 616874;
  font-family: "Roboto", sans-serif;
  width: 300px;
  height: 60px;
  overflow: hidden;
  border-radius: 0.25rem;
  cursor: pointer;
  border: 1px solid #00a82d;
}

.channel-select:active {
  border: 1px solid #00a82d;
}

.continue {
  border-radius: 0.25rem;
}

.active-continue {
  background-color: #00a82d;
  color: #fff;
  cursor: pointer;
}

.disable-conitnue {
  background-color: #e4e4e4bb;
  cursor: not-allowed;
  color: #4f4f50;
  pointer-events: none;
  cursor: not-allowed;
  box-shadow: none;
}

.variable-input-box {
  height: 50px;
  margin-bottom: 5px;
  border-radius: 0.25rem;
}

/*Contact Dialogue*/

.btn-import-contact {
  height: 50px;
  width: 210px;
  border-radius: 8px;
  margin-right: 6px;
  background-color: #00a82d;
  color: #fff;
  cursor: pointer;
}

.import-contact-dialog {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
  border: 2px solid #00a82d;
  border-radius: 5px;
}

.input-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 8px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  padding-left: 15px;
  margin-right: 10px;
}

.input-div-disabled {
  border: 2px solid #f0f2f8;
  pointer-events: none;
  color: #9ea4b5;
}

.input-div-active {
  border: 2px solid #b8bdcc;
  color: #3f4a5f;
}

.input-div-completed {
  border: 2px solid #00a82d;
}

.input-text {
  position: relative;
  margin: 0;
  padding: 0;
}

.csv-error {
  height: 40px;
  width: 100%;
  color: rgba(231, 8, 8, 0.723);
  overflow: hidden;
  border-radius: 4px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 0.8rem;
}


.camp-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://user-images.githubusercontent.com/112541157/194324303-a7a2d823-e510-4740-a152-943416492eff.jpg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;

}

.camp-search:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
  cursor: pointer;

}




.app_list {
  overflow-y: auto !important;
  scrollbar-color: #9EA4B5 #e4e4e4;
  transition: scrollbar-color 0.3s ease-out;
  scrollbar-width: thin;
}

.app_list::-webkit-scrollbar {
  width: 15px;
}

.app_list::-webkit-scrollbar-track {
  border-radius: 100px;
}

.app_list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #9EA4B5;
}