.tips .items-lottie {
    width: 50px !important;
    height: 40px !important;
}

.pricing-home .accordion-button:not(.collapsed) {
    color: #616874 !important;
    background-color: #F0F2F8 !important;
    box-shadow: none !important;
}

.pricing-home .accordion-item:first-of-type {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.pricing-home .accordion-item {
    background-color: none !important;
    border: 0px !important;
}

.pricing-home .accordion-body {
    padding: 0px 20px !important;
}


@media (min-width: 360px) and (max-width: 767.98px) {
    .pricing {
        margin: 0px !important;
    }

    .pricing .top-bar h1 {
        font-size: 24px !important;
    }

    .pricing .top-bar h5 {
        font-size: 15px !important;
    }

    .pricing .main-pricing {
        margin-right: -44px !important;
    }

    .pricing .main-pricing .price-topbar .text-center {
        padding: 0px !important;
        margin: 0px !important;
    }

    .pricing .main-pricing .price-topbar .pop {
        padding: 0px 5px 0px 5px !important;
        margin: 0px 0px -100px -8px !important;
        font-size: 13px !important;
    }

    .pricing .main-pricing .price-topbar .name0 {
        font-size: 20px !important;
    }

    .pricing .main-pricing .price-topbar .name1 {
        font-size: 15px !important;
    }

    .pricing .main-pricing .price-topbar .name2 {
        font-size: 15px !important;
    }

    .pricing .main-pricing .price-topbar .name3 {
        font-size: 12px !important;
        position: relative !important;
        top: -25px !important
    }

    .pricing .main-pricing .price-topbar .name4 {
        font-size: 7px !important;
        position: relative !important;
        top: 22px !important
    }

    .pricing .main-pricing .price-topbar .price {
        font-size: 12px !important;
    }

    .pricing .main-pricing .price-topbar .price3 {
        font-size: 12px !important;
        position: relative !important;
        top: -25px !important
    }

    .pricing .main-pricing .price-topbar .price4 {
        font-size: 12px !important;
        position: relative !important;
        top: 24px !important
    }

    .pricing .main-pricing .price-topbar .cut_price2 {
        font-size: 12px !important;
        position: relative !important;
        top: -6px !important
    }

    .pricing .main-pricing .price-topbar .cut_price3 {
        font-size: 12px !important;
        position: relative !important;
        top: -28px !important
    }

    .pricing .main-pricing .price-topbar .cut_price4 {
        font-size: 12px !important;
        position: relative !important;
        top: 20px !important
    }

    .pricing .main-pricing .accordion {
        margin-right: 0px !important;
    }

    .pricing .main-pricing .accordion .accordion-item .accordion-header button {
        font-size: 13px !important;
    }

    .pricing .main-pricing .accordion .row span {
        font-size: 12px !important;
    }

    .pricing .main-pricing .accordion .row .MuiSvgIcon-root {
        font-size: 12px !important;
    }

    .pricing .main-pricing .accordion .row .text-center span {
        font-size: 9px !important;
    }

    .planTypeUI {
        padding: 0px !important;
        margin: 0px !important;
    }

    .planTypeUI .row {
        padding: 0px !important;
        margin: 0px !important;
    }

    .planTypeUI .row .pTag {
        font-size: 14px !important;
        padding: 0px !important;
        margin-left: -21px !important;
        margin-right: -57px !important;
    }

    .planTypeUI .row .pTag span {
        font-size: 10px !important;
        padding: 0px 6px 0px 6px !important;
        margin: 0px !important;
    }

    .price_comparsion {
        padding: 0px !important;
        margin: 0px !important;
    }

    .price_comparsion .row {
        padding: 0px !important;
        margin: 10px !important;
    }

    /* .price_comparsion .colOne {
         margin-left: -43px !important;
     }

     .price_comparsion .colTwo {
         margin-right: 16px !important;
     }
     .price_comparsion .colThree{
         margin-right: 16px !important;
     }

     .price_comparsion .colFive {
         margin-right: -40px !important;
     } */

    .price_comparsion p {
        font-size: 10px !important;
    }

}


















/* Himanshu CSS */


/* EducationSector */

.him_onboard {
    color: #9EA4B5;
    font-size: 24px;
}

.him_box1 {
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 4px;
    display: flex;
}

.him_f-size {
    font-size: 16px;
}

.him_studentstyle {
    color: #00A82D;
    font-size: 45px;
}

.him_backcolor {
    background-color: #F8FBF7;
}

.him_imagestyle {
    padding-bottom: 70px;
}

.him_Broadcast {
    color: #00A82D;
}

.him_color {
    background-color: #F8FBF7;
}

.him_text-color {
    color: #616874;
    font-size: 33px;
}

.him_zero-color {
    font-size: 25px;
}

/* End of EducationSector */



/* HealthSector */

.him_extra {
    color: #9EA4B5;
    font-size: 24px;
}

.him_box2 {
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 4px;
    display: flex;
}

.him_font-size {
    font-size: 16px;
}

.him_patientstyle {
    color: #00A82D;
    font-size: 46px;
}

.him_bgcolor {
    background-color: #F8FBF7;
}

.him_imgstyle {
    padding-bottom: 60px;
}

/* End of HealthSector */


/* TourTravelSector */

.him_plan {
    color: #9EA4B5;
    font-size: 24px;
}

.him_box3 {
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 4px;
    display: flex;
}

.him_text-size {
    font-size: 16px;
}

.him_Teamstyle {
    color: #00A82D;
    font-size: 35px;
}

.him_backcolor {
    background-color: #F8FBF7;
}

.him_picstyle {
    padding-bottom: 70px;
}

/* End of TourTravelSector */


/* Ecommerce Sector */

.him_provide {
    color: #9EA4B5;
    font-size: 24px;
}

.him_box4 {
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 4px;
    display: flex;
}

.him_t-size {
    font-size: 16px;
}

.him_usestyle {
    color: #00A82D;
    font-size: 35px;
}

.him_backcolor {
    background-color: #F8FBF7;
}

.him_picturestyle {
    padding-bottom: 70px;
}

/* End of Ecommerce Sector */


@media only screen and (max-width: 780px) {

    /* Education Sector */
    .him_fontsize {
        font-size: 31px;
    }

    .him_onboard {
        font-size: 18px !important;
    }

    .him_f-size {
        font-size: 16px;
    }

    .him_studentstyle {
        font-size: 19px;
    }

    .him_imagestyle {
        padding-bottom: 30px;
    }

    .him_Broadcast {
        font-size: 21px;
    }

    .him_text-color {
        font-size: 20px;
    }

    .him_zero-color {
        font-size: 20px;
    }

    .him_mediaa {
        font-size: 19px;
    }

    /* End of Education Sector */


    /* HealthSector */
    .him_font-size {
        font-size: 16px;
    }

    .him_extra {
        font-size: 19px;
    }

    .him_patientstyle {
        font-size: 14px;
    }

    .him_imgstyle {
        padding-bottom: 30px;
    }

    .him_mediawidth {
        width: 50px;
    }

    /* End of HealthSector */


    /* TourTravel Sector */
    .him_plan {
        font-size: 16px;
    }

    .him_Teamstyle {
        font-size: 20px;
    }

    .him_picstyle {
        padding-bottom: 30px;
    }

    /* End of TourTravel Sector */


    /* Ecommerce Sector */

    .him_provide {
        font-size: 16px;
    }

    .him_usestyle {
        font-size: 18px;
    }

    .him_picturestyle {
        padding-bottom: 30px;
    }

    .him_size {
        font-size: 31px;
    }

    /* End of Ecommerce Sector */

}

/* Start of LoginUI */


.him_signcolor {
    color: rgb(0, 168, 45);
    font-weight: 20px;
    font-size: 18px;
    font-weight: 500;
}

.him_marginleft {
    margin-left: 100px;
}

/* End of LoginUI */

/* Start of ResetpasswordUI */
.him_resetbox {
    background-color: #00a82d;
    font-size: 25px;
    margin-top: 200px;
    margin-right: 115px;
}

/* End of ResetpasswordUI */

@media only screen and (max-width: 780px) {

    .him_marginleft {
        margin-left: 1px !important;
    }

    .him_hideimg {
        display: none !important;
    }

    .him_showimg {
        display: block !important;
        background-color: #00a82d !important;
        padding-bottom: 10px !important;
        margin-left: -12px !important;
        margin-right: -211px !important;

    }

    .him_resetbox {
        margin-right: -180px !important;
        font-size: 17px !important;
    }

}


/* Start Newecommerce section1 */
.him_div {
    margin-top: 118px;
}

.him_mtop {
    margin-top: 183px;
}

.him_colorr {
    color: #616874;
    font-size: 24px;
}

.him_bgclass {
    background-color: #F8FBF7;
}

/* End Newecommerce section1 */

@media only screen and (max-width: 780px) {
    .him_colorr {
        font-size: 18px;
    }

    .him_pading {
        padding: 22px !important;
    }

    .him_medis {
        margin-top: -155px !important;

    }
}


/* End of Himanshu CSS */