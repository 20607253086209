.settingSidebar {
    padding: 0px 3px 0px 0px !important;
}

.settingSidebar .Mui-selected {
    color: #00a82d !important;
    background: #efffed !important;
    border-radius: 8px;
    padding: 0px 15px !important;
}

.settingSidebar .MuiButtonBase-root {
    border-radius: 8px;
    padding: 0px 15px !important;
    margin-bottom: 4px !important;
}

.settingSidebar .css-10hburv-MuiTypography-root {
    font-size: 16px !important;
}


.app_list {
    overflow-y: auto !important;
    scrollbar-color: #9EA4B5 #e4e4e4;
    transition: scrollbar-color 0.3s ease-out;
    scrollbar-width: thin;
}

.app_list::-webkit-scrollbar {
    width: 15px;
}

.app_list::-webkit-scrollbar-track {
    border-radius: 100px;
}

.app_list::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #9EA4B5;
}