@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

body a {
  text-decoration: none !important;
}

body a:hover {
  color: #00a82d;
}

body .theme {
  color: #00A82D !important;
}

body .theme_bg {
  background: #00A82D !important;
}

body .theme_grey01 {
  background: #f0f2f8 !important;
  color: #616874 !important;
}

body .theme_grey01:hover {
  background: #f0f2f8 !important;
  color: #616874 !important;
}

body .theme_grey {
  background: #f0f2f8 !important;
  color: #616874 !important;
}

body .theme_grey:hover {
  background: #616874 !important;
  color: #fff !important;
}

body .theme_lite {
  color: #29bc64;
}

body .theme_bg_lite {
  background: #29bc64;
}

body .theme_br {
  border: 1px solid #00a82d;
}

body .theme_br_lite {
  border: 1px solid #29bc64;
}

body .theme_btn_bg {
  background: rgba(2, 132, 37, 0.6) !important;
}

body .boxShadow {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
}

body .bRound {
  border-radius: 40px !important;
}

body .hand {
  cursor: pointer !important;
}

body ::placeholder {
  opacity: 0.6 !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  margin-top: 0;
}

body .clients {
  background: #f8fbf7;
}

body .wbtn {
  border-radius: 15px 15px 15px 15px !important;
  padding: 5px 20px;
  box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
  border: 1px solid #1cf196 !important;
  border-color: #1cf196;
  color: #1cf196;
  background-color: #fff;
  font-weight: bold;
}

body .items-lottie {
  width: 600px !important;
  height: 600px !important;
}

.bulk-message .bulk-wbtn {
  font-size: 18px;
  border-radius: 40px !important;
  padding: 5px 20px;
  box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
  border: 1px solid #128c7e !important;
  border-color: #128c7e;
  color: #fff;
  background-color: #128c7e;
  font-weight: bold;
}

.bulk-message .bulk-wbtn:hover {
  color: #fff;
}

.modal-info-bulk-msg .fa-whatsapp {
  color: #fff;
}

.modal-info-bulk-msg .bulk-wbtn {
  font-size: 18px;
  border-radius: 40px !important;
  padding: 5px 20px;
  box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11);
  border: 1px solid #128c7e !important;
  border-color: #128c7e;
  color: #fff;
  background-color: #128c7e;
  font-weight: bold;
}

.modal-info-bulk-msg .bulk-wbtn:hover {
  color: #fff;
}

body .homeTwo {
  background: rgba(187, 133, 69, 0.11);
}

body .homeFour {
  background: rgba(97, 129, 119, 0.15);
}

body .homeSeven {
  border: 1px solid #0f6550;
  margin: 45px;
  border-radius: 10px;
}

body .homeSeven .forWhatsApp {
  margin: 10px 20px 10px 0px;
  background: #e6f0e4;
  border-radius: 10px;
  padding: 20px;
}

body .homeSeven .forWhatsApp .boxBg {
  box-shadow: 4px 4px 33px #b5ccb1;
  background: #ffffff;
  border-radius: 10px;
}

body .homeRImg {
  height: 385px !important;
}

body .footer {
  background: #f8fbf7;
}

body .footer i {
  background-color: #000;
  color: #fff;
  padding: 8px;
  border-radius: 50% !important;
  margin-left: 6px;
  font-size: 15px;
}

body .tabUI .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #00a82d;
  background-color: #efffed;
  font-size: 14px;
  padding: 2px 10px 2px 10px;
}

body .tabUI .nav-link {
  color: #3f4a5f;
  font-size: 14px;
  padding: 2px 10px 2px 10px;
}

body .tabUI img {
  box-shadow: 0px 0px 6px #00a82d !important;
  border-radius: 10px;
}

body .loginArrow {
  max-width: 0;
  display: inline-block;
  -webkit-transition: color 0.25s 1.5s, max-width 2s;
  transition: color 0.25s 1.5s, max-width 2s;
  white-space: nowrap;
  overflow: hidden;
}

body .loginBtn:hover .loginArrow {
  max-width: 300px;
}

/* Top Bar Notification */

/* End of Top Bar Notification */





.tryWhatsApp .items-lottie {
  width: 300px !important;
  height: 300px !important;
}

.testimonials {
  border-radius: 6% !important;
}

.testimonials .mainSideImg {
  border-radius: 4% 0 0 4% !important;
  box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.11) !important;
}
.testimonials .tesBody .tes_info{
  margin-top: 40px ;
}

.signUpBox .signForm {
  padding: 0px !important;
  margin: 0px -10px 0px -6px !important;
}

.blur_img {
  width: 100% !important;
  height: auto !important;
}

/* Dashboard Chat UI */
.onboardChat .speech-bubble {
  /* position: relative; */
  padding: 6px 10px;
  max-width: 300px;
  color: #3f4a5f;
  font-weight: 400;
  font-size: 14px;
  background: #efffed;
  border-radius: 10px 10px 10px 0px;
}

.onboardChat .speech-bubble:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: -25px;
  bottom: 0;
  width: 40px;
  border-top: 25px solid #efffed;
  border-bottom-right-radius: 6px;
}

.onboardChat .speech-bubble:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: -25px;
  bottom: 0px;
  height: 40px;
  width: 28px;
  background: #fff;
  border-bottom-right-radius: 20px;
}

.onboardChat .speech-bubble-user {
  position: relative;
  padding: 6px 10px;
  max-width: 300px;
  color: #3f4a5f;
  font-weight: 400;
  font-size: 14px;
  background: #f0f2f8;
  border-radius: 10px 10px 0px 10px;
}

.onboardChat .speech-bubble-user:before {
  content: "";
  position: absolute;
  z-index: -1;
  right: -25px;
  bottom: 0;
  width: 40px;
  border-top: 25px solid #f0f2f8;
  border-bottom-left-radius: 6px;
}

.onboardChat .speech-bubble-user:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: -25px;
  bottom: 0px;
  height: 40px;
  width: 28px;
  background: #fff;
  border-bottom-left-radius: 20px;
}

.btn-outline-secondary {
  border-color: #f0f2f8 !important;
}

.btn-outline-secondary:hover {
  border-color: #f0f2f8 !important;
  background: #fff !important;
  color: #9ea4b5 !important;
}

.css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
  border-color: #f0f2f8 !important;
}

.css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input:focus {
  border-color: #f0f2f8 !important;
}

.form-control {
  border: 1px solid #f0f2f8 !important;
  color: #9ea4b5 !important;
}

/* End of Dashboard Chat UI */

/* Left Side Main Menu CSS */

/* End of Left Side Main Menu CSS */

/* Chatbot Static Page UI CSS */
.benefits_box {
  border: 1px solid #21cd5b;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
}

/* End of Chatbot Static Page UI CSS */

/* Profile */
.profile_base_view {
  display: flex;
  background-color: #f8f8f8;
  height: 100vh;
  width: 96vw;
}

.profile_side_menu {
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-size: 14px !important;
  font-weight: bolder !important;
}

.profile_side_menu .Mui-selected {
  color: #00a82d !important;
  background: #efffed !important;
  border-radius: 10px;
  font-weight: 500 !important;
}

.profile_menu_item {
  display: flex;
  flex-direction: column;
  flex: 0.85;
}

.profile_menu_item_android {
  display: flex;
  flex-direction: column;
  flex: 1 !important;
  overflow-x: hidden !important;
}

.profile_menu {
  flex: 1;
}

.profile_menu_item {
  overflow-y: auto !important;
  scrollbar-color: #9EA4B5 #e4e4e4 !important;
  transition: scrollbar-color 0.3s ease-out !important;
  scrollbar-width: thin !important;
}

.profile_menu_item::-webkit-scrollbar {
  width: 15px !important;
}

.profile_menu_item::-webkit-scrollbar-track {
  border-radius: 100px !important;
}

.profile_menu_item::-webkit-scrollbar-thumb {
  border-radius: 100px !important;
  border: 5px solid transparent !important;
  background-clip: content-box !important;
  background-color: #9EA4B5 !important;
}

.plans_android {
  padding-bottom: 1700px !important;
}

.plan_action_bar {
  overflow-x: hidden !important;
  position: absolute;
  bottom: 0px;
  margin-left: -24px !important;
  padding-left: -24px !important;
  width: 80.8%;
}

.profile_side_menu .Mui-selected {
  color: #00a82d !important;
  background: #efffed !important;
  margin: 6px;
  border-radius: 10px;
}

.profile_side_menu .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  color: #00a82d !important;
  background: #efffed !important;
  margin: 6px;
  border-radius: 10px;
}

.active_plan_view {
  overflow-x: hidden !important;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #00a82d !important;
}

.css-11yukd5-MuiTabs-indicator {
  background: #00a82d !important;
}

.price_btn_monthly {
  border-radius: 20px 0px 0px 20px !important;
}

.price_btn_quartely {
  border-radius: 0px !important;
  border-left: 0.2px solid rgba(2, 132, 37, 0.6) !important;
  border-right: 0.2px solid rgba(2, 132, 37, 0.6) !important;
}

.price_btn_yearly {
  border-radius: 0px 20px 20px 0px !important;
}

.no_active_plan_view {
  background-image: url("https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/upgrade_bg.png");
  background-repeat: no-repeat !important;
  background-size: 100% 210px !important;
}

/* End of Profile */

/* Conversation CSS */
.app_body {
  display: flex;
  background-color: #fff;
  height: 100vh;
  width: 96vw;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.75);
}

.broadcast_leftSidebar {
  padding-top: 16px;
  padding-right: 16px;
  height: 100% !important;
}

.broadcast_leftSidebar .Mui-selected {
  color: #00a82d !important;
  background: #efffed !important;
  border-radius: 10px;
  font-weight: 500 !important;
}

.sideBarMain {
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: #9ea4b5;
  font-size: 14px !important;
  font-weight: bolder !important;
}

.sideBarMain .Mui-selected {
  color: #00a82d !important;
  background: #efffed !important;
  border-radius: 10px;
  font-weight: 500 !important;
}

.sideBarChats {
  display: flex;
  flex-direction: column;
  padding: 12px;
  color: #9ea4b5;
  font-size: 14px !important;
  font-weight: bolder !important;
  overflow-y: auto !important;
  scrollbar-color: #9EA4B5 #e4e4e4 !important;
  transition: scrollbar-color 0.3s ease-out !important;
  scrollbar-width: thin !important;
}

.userInfoChats {
  display: flex;
  flex-direction: column;
  color: #9ea4b5;
  font-weight: bolder !important;
  overflow-y: auto !important;
  scrollbar-color: #9EA4B5 #e4e4e4 !important;
  transition: scrollbar-color 0.3s ease-out !important;
  scrollbar-width: thin !important;
}

.chatSection {
  display: flex !important;
  height: 70% !important;
  flex-direction: column !important;
  overflow-y: auto !important;
  scrollbar-color: #9EA4B5 #e4e4e4 !important;
  transition: scrollbar-color 0.3s ease-out !important;
  scrollbar-width: thin !important;
}

.campaignList {
  overflow-y: auto !important;
  scrollbar-color: #9EA4B5 #e4e4e4 !important;
  transition: scrollbar-color 0.3s ease-out !important;
  scrollbar-width: thin !important;
}

.chatSection::-webkit-scrollbar,
.campaignList::-webkit-scrollbar,
.userInfoChats::-webkit-scrollbar,
.sideBarChats::-webkit-scrollbar {
  width: 15px !important;
}

.chatSection::-webkit-scrollbar-track,
.campaignList::-webkit-scrollbar-track,
.userInfoChats::-webkit-scrollbar-track,
.sideBarChats::-webkit-scrollbar-track {
  border-radius: 100px !important;
}

.chatSection::-webkit-scrollbar-thumb,
.campaignList::-webkit-scrollbar-thumb,
.userInfoChats::-webkit-scrollbar-thumb,
.sideBarChats::-webkit-scrollbar-thumb {
  border-radius: 100px !important;
  border: 5px solid transparent !important;
  background-clip: content-box !important;
  background-color: #9EA4B5 !important;
}

.sidebar {
  display: flex;
  flex-direction: column;
  border-right: 0.2px solid rgb(233, 233, 233);
}

.sidebar_header {
  display: flex;
  justify-content: space-between;
  padding: 9px;
  border-right: 1px solid lightgray;
}

.sidebar_header_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 10vw;
}

.sidebar_header_right .MuiSvgIcon-root {
  font-size: 24px !important;
}

.sidebar_search {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 65px;
  padding: 30px 10px 30px 10px;
  border-bottom: 0.2px solid rgb(233, 233, 233);
}

.sidebar_searchContainer {
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgb(233, 233, 233);
}

.sidebar_searchContainer .MuiSvgIcon-root {
  color: gray;
  padding: 0px 0px 0px 10px;
}

.sidebar_searchContainer input {
  border: none;
  outline-width: 0;
  margin-left: 10px;
}

.sidebar_chats {
  flex: 1;
  background-color: white;
  overflow-y: auto;
  scrollbar-color: #d4aa70 #e4e4e4;
  transition: scrollbar-color 0.3s ease-out;
  scrollbar-width: thin;
}

.sidebar_chats:hover {
  overflow-y: auto;
  scrollbar-color: #d4aa70 #e4e4e4;
  transition: scrollbar-color 0.3s ease-out;
  scrollbar-width: thin;
}

.sidebarChat {
  display: flex;
  padding: 12px 0px 0px 10px;
  cursor: pointer;
  border-bottom: 1px solid #f6f6f6;
}

.sidebarChat:hover {
  background-color: rgba(41, 188, 100, 0.2);
}

.sidebarChat_Info h2 {
  font-size: 15px;
  margin-top: 10px;
}

.sidebarChat_Info {
  margin-left: 15px;
  margin-top: -10px !important;
  padding-bottom: 0px;
}

.sidebarChat_Info p {
  margin-top: 10px !important;
  font-size: 12px !important;
  padding-bottom: 0px;
}

.sidebarChat_Info .lastMsg {
  margin-top: -13px !important;
  font-size: 13px !important;
  padding-bottom: 0px !important;
}

.sidebarChat_Info .unread_msg {
  margin-top: -13px !important;
  font-size: 13px !important;
}



.endUserInfo {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  border-left: 0.3px solid lightgray;
}

.chat_header {
  padding: 0px 20px;
  display: flex;
  align-items: center;
  background-color: #fcfcfc;
  border-bottom: 1px solid lightgray;
}

.chat_headerInfo {
  flex: 1;
  padding: 2px 0px 0px 20px;
}

.chat_headerInfo h4 {
  margin-top: 10px !important;
  margin-bottom: -4px !important;
  font-size: 18px !important;
  font-weight: 600;
}

.chat_headerInfo p {
  color: gray;
  font-size: 14px !important;
}

.chat_body {
  flex: 1;
  padding: 30px;
  overflow-y: auto !important;
  scrollbar-color: #d4aa70 #e4e4e4;
  transition: scrollbar-color 0.3s ease-out;
  scrollbar-width: thin;
  overflow-x: hidden !important;
}

.sidebar_chats::-webkit-scrollbar,
.chat_body::-webkit-scrollbar {
  width: 15px;
}

.sidebar_chats::-webkit-scrollbar,
.chat_body::-webkit-scrollbar-track {
  border-radius: 100px;
}

.sidebar_chats::-webkit-scrollbar,
.chat_body::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #00836b;
}

.chat_message {
  position: relative;
  font-size: 16px;
  padding: 10px 40px 0px 10px !important;
  width: fit-content;
  border-radius: 10px;
  background-color: #00a82d;
  color: #fff !important;
  margin-bottom: 30px;
}

.chat_timestamp {
  font-size: x-small;
  position: absolute;
  margin-top: 6px;
  margin-bottom: 10px;
  width: fit-content;
  right: 0px;
  color: #707070 !important;
  margin-bottom: -10px !important;
  padding-bottom: -10px !important;
}

.double-tick {
  width: 15px !important;
  color: #0b86df !important;
}

.chat_name {
  margin: -10px 20px 0px 0px !important;
  font-weight: bold !important;
  font-size: 12px;
  color: #3c8b00;
}

.chat_fromBusiness {
  margin-left: auto;
  background-color: #fff;
}

.cFooter {
  height: auto;
}

.chat_footer {
  align-items: center;
  border-top: 1px solid lightgray;
  background: #fff !important;
}

.chat_footer_2 {
  border-top: 1px solid lightgray;
  padding: 0px 20px 10px 0px !important;
  display: flex;
  align-items: center;
  background: #fff;
}

.chat_footer_2 .chat_footer_icons {
  flex: 1;
  padding: 10px 0px 0px 20px;
}

.chat_footer div {
  flex: 1;
  display: flex;
}

.chat_footer div textarea {
  flex: 1;
  padding: 10px;
  border: none;
  outline-width: 0;
  padding: 0px 0px 0px 30px !important;
}

.chat_footer div button {
  /* display: none; */
  border: none;
  outline-width: 0;
}

.chat_footer .MuiSvgIcon-root {
  padding: 1px;
  color: gray;
}

/* End of Conversation CSS */

/* Quick Guide Page UI CSS */
.quick_features:hover {
  box-shadow: 0px 0px 6px #00a82d !important;
}

.key-points th,
.key-points td {
  font-size: 15px !important;
}

/* End of Quick Guide Page UI CSS */

/* Onboarding CSS */

.onboarding .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
  border: 2px solid #f0f2f8;
}

.onboarding_anim .items-lottie {
  width: 200px !important;
  height: 200px !important;
}

/* End of Onboarding CSS */


.css-1mrn996.Mui-selected {
  color: #00A82D !important;
}

.css-axpu1l {
  background-color: #00A82D !important;
}

.carousel-indicators {
  display: none !important;
}

/* Media Queries */
@media (max-width: 767px) {

  .key-points th,
  .key-points td {
    font-size: 12px !important;
  }
}

@media (min-width: 360px) and (max-width: 767.98px) {
  .qg_one .quick_features {
    padding: 6px !important;
  }

  .qg_one img {
    width: 40px !important;
  }

  .qg_one p {
    font-size: 14px !important;
  }

  .qg_two img {
    width: 70px !important;
  }

  .qg_two p {
    font-size: 14px !important;
  }

  .qg_three .images {
    padding: 10px !important;
  }

  .qg_three .text {
    padding: 10px !important;
    font-size: 14px !important;
  }

  .key-points th,
  .key-points td {
    font-size: 12px !important;
  }

  .blogView {
    padding-right: -15px !important;
    padding-left: -15px !important;
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .tryWhatsApp {
    padding: 0px !important;
  }

  .tryWhatsApp .innerBox {
    padding-right: -15px !important;
    padding-left: -15px !important;
    margin-right: -15px !important;
    margin-left: -15px !important;
  }

  .tryWhatsApp .tryWhatsAppImage {
    position: relative !important;
    top: 0px !important;
    margin-bottom: 50px !important;
  }

  .tryWhatsApp .btn {
    margin-top: 6px !important;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .iframeVideo {
    width: 340px !important;
    height: 180px !important;
  }

  .footer .in {
    padding: 0px 0px 0px 10px !important;
    margin: 0px !important;
  }

  .footer .dImg {
    width: 140px;
  }

  .footer .social i {
    background-color: #000;
    color: #fff;
    padding: 6px !important;
    border-radius: 50% !important;
    margin-left: 6px;
    font-size: 12px;
  }

  .footer .social .fb {
    padding: 6px 8px 6px 8px !important;
    border-radius: 50% !important;
  }

  body .onSmallBtn {
    margin-top: 20px !important;
  }

  .hOne .tx1 {
    font-size: 12px !important;
    margin-top: -30px !important;
  }

  .hOne .tx2 {
    font-size: 16px !important;
    margin-top: -4px !important;
  }

  .hOne img {
    margin-top: 110px !important;
  }

  .clients h4 {
    margin-top: 10px !important;
  }

  .hTwo .pTwo {
    padding: 0px !important;
  }

  .hTwo img,
  .hThree img,
  .hFour img,
  .hFive img,
  .hSix img {
    margin-top: 20px !important;
    height: auto !important;
  }

  .hThree .pThree {
    padding: 8px !important;
  }

  .hFour .pFour {
    padding: 0px !important;
  }

  .hFive .pFive {
    padding: 8px !important;
  }

  .hSix .pSix {
    padding: 0px !important;
  }

  .homeSeven {
    margin: 10px 0px 10px 0px !important;
    padding: 0px !important;
  }

  .hSeven .tx1 {
    margin: 20px 0px 10px 0px !important;
    padding: 0px !important;
  }

  .hSeven .forWhatsApp {
    margin: 6px 100px 0px 6px !important;
    padding: 0px 0px 6px 0px !important;
    width: 324px !important;
  }

  .hSeven .forWhatsApp h5 {
    margin: 0px 24px 0px 18px !important;
    padding: 0px !important;
    font-size: 15px !important;
  }

  .hSeven .forWhatsApp .col {
    padding: 0px !important;
    margin: 0px !important;
  }

  .hSeven {
    margin: 10px 55px 10px 0px !important;
    padding: 0px !important;
  }

  .hSeven .hSevenB .greenTx {
    margin-top: -40px !important;
  }

  .hSeven .hSevenB .image {
    margin-top: -70px !important;
    margin-bottom: -10px !important;
  }

  .hSeven .forWhatsApp .boxBg {
    margin: 3px !important;
    padding: 6px 0px 6px 15px !important;
  }

  .hSeven .forWhatsApp .boxBg h5 {
    font-size: 15px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  .headingTx {
    font-size: 24px !important;
  }

  .descTx {
    font-size: 12px !important;
  }

  .signUpBox .signForm {
    padding: 0px !important;
    margin: 0px -10px 0px -6px !important;
  }

  .testimonials,
  .testimonials .mainSideImg {
    height: 150px !important;
  }

  .testimonials .logo {
    height: 30px !important;
  }

  .testimonials .tesBody {
    padding: 10px !important;
  }

  .testimonials .tesBody .tes_info {
    margin-top: 6px !important;
  }

  .testimonials p {
    font-size: 8px !important;
  }

  .testimonials h3 {
    margin-top: 0px !important;
    font-size: 10px !important;
  }

  .testimonials h6 {
    margin-top: -10px !important;
    font-size: 10px !important;
  }


}


/* Chat Notification */
.Toastify__toast-theme--light {
  background: #F0F2F8 !important;
}

/* End of Chat Notification */




@media (min-width: 768px) and (max-width: 799px) {

  .key-points th,
  .key-points td {
    font-size: 12px !important;
  }
}

@media (min-width: 800px) and (max-width: 979px) {

  .key-points th,
  .key-points td {
    font-size: 12px !important;
  }
}

@media (min-width: 980px) and (max-width: 1199.98px) {

  .key-points th,
  .key-points td {
    font-size: 12px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {

  .key-points th,
  .key-points td {
    font-size: 12px !important;
  }
}

/* End of Media Queries */