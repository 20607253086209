.chatbot_ui .css-ahj2mt-MuiTypography-root {
	font-size: 12px !important;
}

.chatbot_ui .table>:not(caption)>*>* {
	padding: 4px 14px !important;
}

.leftsidebar .css-1mcnwpj-MuiList-root {
	margin: 0px -10px 0px -10px !important;
	width: auto !important;
}


.react-flow .react-flow__node-output {
	width: 210px;
	font-size: 12px;
	background: #fff !important;
	border: 1px solid #fff;
	border-radius: 0px 0px 4px 4px;
	text-align: left;
	word-wrap: break-word;
	cursor: move;
}

.react-flow .react-flow__node-input {
	padding: 0px;
	color: #3F4A5F;
	border: 1px solid #F0F2F8 !important;
	border-radius: 0px !important;
}

.node_button_input {
	height: 100%;
	width: 100%;
	outline: none;
	border: 0;
	text-align: start;
	background: rgba(0, 0, 0, 0);
	display: inline-block;
	vertical-align: middle;
	overflow: hidden;
	white-space: nowrap;
	margin-top: 5px;
	text-overflow: ellipsis;
}

.react-flow__node-default {
	border: none !important
}

.react-flow__node-default.selectable,
.react-flow__node-group.selectable,
.react-flow__node-input.selectable,
.react-flow__node-output.selectable {
	box-shadow: 0 1px 4px 1px rgb(0 0 0 / 8%);
}

.node_body_info {
	width: 16px;
	height: 16px;
	color: white;
	background: red;
	border-radius: 5px;
	font-size: 0.9vw;
	text-align: center;
}

.node_body_menu_title {
	width: 100%;
	color: #147EFB;
	background: white;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	font-size: 0.9vw;
	position: absolute;
	bottom: 0;
	padding: 8px;
	margin-top: 4px;
	padding-top: 4.5px;
	margin-left: -10px;
}

.node_body_error {
	width: 100%;
	background: red;
	color: white;
	text-align: center;
}

.node_body_header {
	width: 100%;
	outline: none;
	border: 0;
	text-align: left;
	font-weight: bold;
	margin-bottom: 8px;
	resize: none;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	max-height: 30px;
}

.node_body_body {
	width: 100%;
	outline: none;
	border: 0;
	text-align: left;
	margin-bottom: 8px;
	resize: none;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	max-height: 70px;
}

.node_body_footer {
	width: 100%;
	outline: none;
	border: 0;
	text-align: left;
	color: rgb(161, 161, 161);
	margin-bottom: 8px;
	resize: none;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	max-height: 30px;
}

.chatbotLeftSideBar .Mui-selected {
	color: #00A82D !important;
	background: #EFFFED !important;
	border-radius: 10px;
	font-weight: 500 !important;
}

.templateView {
	background-image: url("https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/wtx_chat_bg.png");
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: 100% 100%;
	height: 100vh;
	min-height: 100%;
}

.template_name:hover {
	text-decoration: underline !important;
	color: #147EFB !important;
}



.react-flow .react-flow__node-default.selectable.selected,
.react-flow__node-group.selectable.selected,
.react-flow__node-input.selectable.selected,
.react-flow__node-output.selectable.selected {
	background: #fff691;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}


.react-flow__node-input.selectable {
	box-shadow: none;
}

.react-flow .react-flow__edge-path {
	stroke: #a5a5a574;
	stroke-width: 2;
	stroke-linecap: round;
}

.react-flow .react-flow__edge.selected .react-flow__edge-path {
	stroke: #fff691;
	stroke-width: 4;
}

.react-flow .react-flow__handle {
	padding: 8px;
	width: 10px;
	height: 10px;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
	background-size: 100% 100%;
}

.react-flow .react-flow__handle-top {
	background: #3F4A5F !important;
}

.react-flow .react-flow__handle-top::before {
	content: '' url("https://whatstool.in/wtb_files/images/plus_white_icon.svg");
	position: absolute;
	top: -4px;
	left: -4px;
}

.react-flow .react-flow__handle-right {
	background: #00A82D !important;
	top: 20%;
	margin-right: -12px;
}

.react-flow .react-flow__handle-left {
	background: #BB7433 !important;
	top: 20%;
	margin-left: -12px;
}

.react-flow .react-flow__handle-right::before {
	content: '' url("https://whatstool.in/wtb_files/images/plus_white_icon.svg");
	position: absolute;
	top: -4px;
	left: -4px;
}

.react-flow .react-flow__handle-left::before {
	content: '' url("https://whatstool.in/wtb_files/images/plus_white_icon.svg");
	position: absolute;
	top: -4px;
	left: -4px;
}

.dndflow {
	flex-direction: column;
	display: flex;
}

.dndflow aside {
	border-right: 1px solid #F0F2F8;
	padding: 15px 10px;
	font-size: 12px;
	background: #FFF;
}

.leftsidebar {
	background: #fff;
	overflow-x: hidden !important;
}


.bot_flow_list:hover .bot_flow_option {
	display: block !important;
}

.rightsidebar {
	background: #fff;
	overflow-x: hidden !important;
}

.propertiesSection .css-10hburv-MuiTypography-root {
	font-weight: bold !important;
	color: #3F4A5F !important;
}


.MuiDrawer-paperAnchorRight {
	width: 400px !important;
}



.dndflow aside>* {
	margin-bottom: 10px;
}

.dndflow aside .description {
	margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
	flex-grow: 1;
	height: 91%;
}

.dndflow_node {
	background: #EFFFED;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border: 0;
	border-radius: 5px;
	color: #3F4A5F;
	font-size: 12px;
	cursor: grab;
	padding: 10px;
}

.dndflow_node_button_container {
	bottom: 0;
	width: 100%;
	margin-bottom: -1px;
}

.hline {
	height: 1px;
	width: 100%;
	background: #111;
}

.dndflow_section {
	font-size: 15px;
	color: #616874;
	font-weight: 500;
}

.dndflow_node_button {
	background: #fcfcfc;
	border-radius: 5px;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: center;
	width: 100%;
	margin-top: 1px;
	color: #147EFB;

}

.dndflow_node_button_add {
	width: 85%;
	border-radius: 5px;
	text-align: center;
	background: #fcfcfc;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	padding: 6px;
}

.dndflow_node_button_add:hover {
	background: #e5e5e5;
}

.dndflow_inut {
	background: #fcfcfc;
	border-radius: 5px;
	font-size: 12px;
	padding: 1px;
	text-align: left;
	margin-bottom: 5px;
	resize: none;
	width: 100%;
	border-color: rgba(0, 0, 0, 0);
}

.dndflow_inut_button {
	background: #fcfcfc;
	border-radius: 5px;
	font-size: 12px;
	padding: 1px;
	text-align: left;
	margin-bottom: 5px;
	resize: none;
	width: 85%;
	border-color: rgba(0, 0, 0, 0);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.dndflow_inut_button_no_border {
	background: #fcfcfc;
	font-size: 12px;
	padding-top: 1px;
	padding-bottom: 1px;
	text-align: left;
	resize: none;
	border-color: rgba(0, 0, 0, 0);
	margin-top: 2px;
}

.dndflow_node_button_trash {
	background: #e5e5e5;
	float: right;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 5px;
	padding-right: 5px;
}

.dndflow_node_button_trash:hover {
	background: #ff7c7c;
	cursor: default;
}

.propertiesSection .css-1x4vipr-MuiButtonBase-root-MuiToggleButton-root {
	color: #00A82D !important;
}

.propertiesSection .css-1x4vipr-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
	color: #fff !important;
	background-color: #00A82D !important;
}

.css-1y8594u-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
	color: #B8BDCC !important;
}

.rightSideBarMenu .MuiInputLabel-root {
	display: none !important;
}

.rightSideBarMenu .MuiOutlinedInput-root.Mui-focused fieldset {
	border-color: #B8BDCC !important;
}

.botsearch .form-control {
	border: none !important
}

.imgChecked:hover {
	border: 1px solid #00A82D !important;
}

.leftSideBarMenu,
.rightSideBarMenu {
	overflow-y: auto !important;
	scrollbar-color: #9EA4B5 #e4e4e4 !important;
	transition: scrollbar-color 0.3s ease-out !important;
	scrollbar-width: thin !important;
}


.leftSideBarMenu::-webkit-scrollbar,
.rightSideBarMenu::-webkit-scrollbar {
	width: 15px !important;
}

.leftSideBarMenu::-webkit-scrollbar-track,
.rightSideBarMenu::-webkit-scrollbar-track {
	border-radius: 100px !important;
}

.leftSideBarMenu::-webkit-scrollbar-thumb,
.rightSideBarMenu::-webkit-scrollbar-thumb {
	border-radius: 100px !important;
	border: 5px solid transparent !important;
	background-clip: content-box !important;
	background-color: #9EA4B5 !important;
}



@media screen and (min-width: 768px) {
	.dndflow {
		flex-direction: row;
	}

	.rightSideBarMenu {
		width: 390px !important;
		max-width: 420px !important;
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.css-1y8594u-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
		color: #B8BDCC !important;
	}

	/* .leftSideBarMenu {
		width: 250px !important;
		max-width: 250px !important;
	} */
}

.horizontal-line {
	width: 100%;
	height: 1px;
	background: grey;
}

/* drop down */

body {
	color: #000000;
	font-family: Sans-Serif;
	background-color: #f6f6f6;
}

a {
	text-decoration: none;
	color: #000000;
}

a:hover {
	color: #222222
}

/* icons */

.gg-comment {
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs, 1));
	width: 20px;
	height: 16px;
	border: 2px solid;
	border-bottom: 0;
	box-shadow:
		-6px 8px 0 -6px,
		6px 8px 0 -6px
}

.gg-comment::after,
.gg-comment::before {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 8px
}

.gg-comment::before {
	border: 2px solid;
	border-top-color: transparent;
	border-bottom-left-radius: 20px;
	right: 4px;
	bottom: -6px;
	height: 6px
}

.gg-comment::after {
	height: 2px;
	background: currentColor;
	box-shadow: 0 4px 0 0;
	left: 4px;
	top: 4px
}

.gg-menu {
	transform: scale(var(--ggs, 1));
	margin-top: 9px;
}

.gg-menu,
.gg-menu::after,
.gg-menu::before {
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 20px;
	height: 2px;
	border-radius: 5px;
	background: currentColor
}

.gg-menu::after,
.gg-menu::before {
	content: "";
	position: absolute;
	top: -6px
}

.gg-menu::after {
	top: 6px
}

.gg-trash {
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs, 1));
	width: 10px;
	height: 12px;
	border: 2px solid transparent;
	box-shadow:
		0 0 0 2px,
		inset -2px 0 0,
		inset 2px 0 0;
	border-bottom-left-radius: 1px;
	border-bottom-right-radius: 1px;
	margin-top: 8px;
	margin-left: 8px;
}

.gg-trash::after,
.gg-trash::before {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute
}

.gg-trash::after {
	background: currentColor;
	border-radius: 5px;
	width: 16px;
	height: 2px;
	top: -4px;
	left: -5px
}

.gg-trash::before {
	width: 10px;
	height: 4px;
	border: 2px solid;
	border-bottom: transparent;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	top: -7px;
	left: -2px
}

.gg-trash:hover {
	color: #ff7c7c;
}




/* List Scroll */
.list_scroll {
	overflow-y: auto !important;
	scrollbar-color: #B8BDCC #e4e4e4;
	transition: scrollbar-color 0.3s ease-out;
	scrollbar-width: thin;
}

.list_scroll::-webkit-scrollbar {
	width: 15px;
}

.list_scroll::-webkit-scrollbar-track {
	border-radius: 100px;
}

.list_scroll::-webkit-scrollbar-thumb {
	border-radius: 100px;
	border: 5px solid transparent;
	background-clip: content-box;
	background-color: #B8BDCC;
}

/* End of List Scroll */

/* custom edge */
.edgebutton {
	width: 20px !important;
	height: 30px !important;
	background: #eee;
	cursor: pointer;
	border-radius: 50%;
}

.edgebutton:hover {
	background: red;
}

.edgebutton-foreignobject div {
	background: transparent;
	width: 40px;
	height: 40px;
	min-height: 40px;
}

/* end custom edge */