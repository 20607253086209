/* Ecommerce HomePage by Himashu */
@media only screen and (max-width: 780px) {

    .him_ecom_fsize {
      font-size: 23px !important;
    }
  
  
  }
  
  /* End of Ecommerce HomePage by Himashu */