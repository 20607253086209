.contacts_crm_ui .css-ahj2mt-MuiTypography-root {
    font-size: 12px !important;
}

.contacts_crm_ui .table>:not(caption)>*>* {
    padding: 6px 14px !important;
}



.app_sidemenu {
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-size: 14px !important;
    font-weight: bolder !important;
}

.app_sidemenu .Mui-selected {
    color: #00a82d !important;
    background: #efffed !important;
    border-radius: 10px;
    font-weight: 500 !important;
}

.app_menu_item {
    display: flex;
    flex-direction: column;
}

.app_list {
    overflow-y: auto !important;
    scrollbar-color: #9EA4B5 #e4e4e4;
    transition: scrollbar-color 0.3s ease-out;
    scrollbar-width: thin;
}

.app_list::-webkit-scrollbar {
    width: 15px;
}

.app_list::-webkit-scrollbar-track {
    border-radius: 100px;
}

.app_list::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #9EA4B5;
}

.app_info_item {
    display: flex;
    flex-direction: column;
}