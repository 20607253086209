#industry-dropdown {
    color: #3F4A5F !important;
}

.wt_loader .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 50% !important;
    padding: 15px 12px 0px 15px !important;
}
.template_ui_toggle .css-ueukts-MuiButtonBase-root-MuiToggleButton-root{
    padding: 4px !important;
    margin-top: 2px !important;
}